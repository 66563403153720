
export default {
    name: 'DateRange',
    props: ['globalControl'],
    data () {
      return { }
    },
    methods: {},
    created () {},
}
