export class Colors {
  static colorBarSent = '#3F51B5'
  static colorBarDelivered = '#00e396'
  static colorBarUndelivered = '#D7263D'
  static colorBarOpened = '#feb019'
  static colorBarClicked = '#5A97CF'
  static colorBarSoftBounced = '#DD4477'
  static colorBarHardBounced = '#990099'
  static colorBarUnsubscribed = '#607D8B'
  static colorBarUnsubscribedLanding = '#bc4042'
  static colorChartSent = '#3F51B5'
  static colorChartDelivered = '#00e396'
  static colorChartUndelivered = '#ff4560'
  static colorChartFailed = '#ff3560'
  static colorChartOpened = '#feb019'
  static colorChartUnopened = '#D7263D'
  static colorChartUniqueClicks = '#2983FF'
  static colorChartTotalClicks = '#3F51B5'
  static colorChartSoftBounced = '#DD4477'
  static colorChartHardBounced = '#990099'
  static colorChartUnsubscribed = '#ff4560'
  static colorChartUnsubscribedLanding = '#ff4560'
  static colorChartEmptyBar = '#bce0fc'
  static colorChartOpened = '#feb019'
  static colorChartNotOpened = '#D7263D'
  static colorChartClicked = '#5A97CF'
  static colorChartNotClicked = '#2983FF'
  static colorChartResponded = '#FF8000'

  static statistics = {
    SENT_TOTAL: '#008FFB',
    DELIVERED_TOTAL: '#00E396',
    PENDING: '#FEB019',
    UNDELIVERED_TOTAL: '#FF4560',
    FAILED_TOTAL: '#ff7235',
    CLICKS: '#775DD0',
    CLICKS_UNIQUE: '#A300D6',
    OPENED: '#001DB2',
    OPENED_UNIQUE: '#81D4FA',
    UNOPENED: '#F59344',
    UNSUBSCRIBED_TOTAL: '#D7263D',
    UNSUBSCRIBE_URL: '#417E7D',
    LANDING_PAGE: '#1B998B',
    FORM: '#4CAF50',
    ATTACHMENT: '#90EE7E',
    URL: '#C5D86D',
    MOBILE: '#B2A69C',
    BROWSER: '#57676F',
    SOFT_BOUNCES: '#FD6A6A',
    HARD_BOUNCES: '#EA3546',
    PLAY_AND_SEND_SMS: '#0000c9',
    CALL_TRANSFER: '#1d1dc6',
    GET_DATA_WHILE_PLAYING: '#3f3fff',
    PLAY: '#6666ff',
    REPEAT_MESSAGE: '#9999ff',
    ADD_TO_BLACKLIST: '#bfbfff',
  }

  static statusesColors = {
    SENDING: {
      color: '#00bcd4',
      base: 'blue',
    },
    PROCESSING: {
      color: '#00bcd4',
      base: 'blue',
    },
    SYNCING: {
      color: '#2edb89',
      base: 'green',
    },
    PAUSED: {
      color: '#ff9800',
      base: 'orange',
    },
    FINISHED: {
      color: '#2edb89',
      base: 'green',
    },
    CANCELLED: {
      color: '#EF5350',
      base: 'red',
    },
    PREPARING_IMPORT: {
      color: '#ff9800',
      base: 'orange',
    },
    PENDING: {
      color: '#ff9800',
      base: 'orange',
    },
    SAVED: {
      color: '#607D8B',
      base: 'grey',
    },
    ENQUEUING: {
      color: '#607D8B',
      base: 'grey',
    },
    EDITING: {
      color: '#607D8B',
      base: 'grey',
    },
    PREPARING: {
      color: '#ffbf2f',
      base: 'orange',
    },
    AUTOMATED: {
      color: 'blue',
      base: 'blue',
    },
    OPENED: {
      color: '#00bcd4',
      base: 'blue',
    },
    API: {
      color: '#00bcd4',
      base: 'blue',
    },
    WAITING: {
      color: '#607D8B',
      base: 'grey',
    },
    IMPORTING: {
      color: '#ff9800',
      base: 'orange',
    },
    VALIDATING: {
      color: '#fbc02d',
      base: 'orange',
    },
    VALIDATED: {
      color: '#8bc34a',
      base: 'green',
    },
    ACTIVE: {
      color: '#00bcd4',
      base: 'blue',
    },
  }
}
