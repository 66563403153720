import { Service } from './service'

const PATH = 'import/'

class ImportService extends Service {
  importContactsFile (params = {}) {
    return this.post(PATH + 'import-contacts-file', params)
  }

  getMyImports (params = {}) {
    return this.get(PATH + 'get-imports', params)
  }

  getMySync (params = {}) {
    return this.post(PATH + 'get-sync-imports', params)
  }

  delete (params = {}) {
    return this.get(PATH + 'delete/' + params)
  }

  updateStatus (params = {}, statusId) {
    return this.get(PATH + 'updateStatus/' + statusId + '/' + params.id)
  }

  getSyncToEdit (params = {}) {
    return this.post(PATH + 'edit-syncro', params)
  }

  getSyncData () {
    return this.get(PATH + 'get-sync-data')
  }
}

export default new ImportService()
