import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import ImportService from '@/services/import.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import moment from 'moment'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { Colors } from '@/classes/colors/Colors'

export default {
  name: 'MySync',
  components: { HeaderTopDashboard, StatsTitle, DateRange },
  data: function () {
    return {
      invalid: false,
      tab: [],
      content: [],
      options: {},
      loading: false,
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      selected: [],
      totalItems: 0,
      editedItem: {
      },
      defaultItem: {
      },
      data: [],
      ready: true,
      statusesColors: Colors.statusesColors,
      refresh: undefined,
    }
  },
  methods: {
    dateFormat (date) {
      return moment(String(date)).format('YYYY/MM/DD')
    },
    getMySync () {
      // EventBus.$emit('showLoading', true)
      const params = this.getParams()
      // this.content = []
      this.loading = true
      ImportService.getMySync(params)
        .then(
          (response) => {
            this.content = response.contact.data
            this.itemsPerPage = parseInt(response.contact.per_page)
            this.page = response.contact.current_page
            this.totalItems = response.contact.total
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          // EventBus.$emit('showLoading', false)
        })
    },
    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        type: 'synchronized',
      }
      return params
    },
    syncNow (item, statusId) {
      this.editedIndex = this.content.indexOf(item)
      ImportService.updateStatus(item, statusId)
        .then(
          (response) => {
            this.getMySync()
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    editItem (item) {
      this.editedIndex = this.content.indexOf(item)
    },
    sync (item) {
      this.editedIndex = this.content.indexOf(item)
      ImportService.sync(item)
        .then(
          (response) => {
            this.getParams()
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    deleteItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, this.content[this.editedIndex])
      this.dialogDelete = true
      this.getMySync()
    },

    deleteItemConfirm () {
      ImportService.delete(this.editedItem.id)
      .then(
        () => {
          this.dialogDelete = false
          this.getMySync()
        },
        () => { },
      )
      .finally(() => {
        this.loading = false
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    statusProgressColor (item) {
      return this.statusesColors[this.statuses[item.status_id]].base + ' darken-2'
    },
    statusColor (item) {
      return this.statusesColors[this.statuses[item.status_id]]?.color
    },
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    headers () {
      return [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: this.$t('Fecha'), align: 'start', value: 'created_at' },
        { text: this.$t('Nombre'), align: 'start', value: 'name' },
        { text: this.$t('Archivo'), align: 'start', value: 'original_filename' },
        { text: this.$t('Sincronización'), align: 'start', value: 'frequency' },
        { text: this.$t('Última Sincronización'), align: 'start', value: 'updated_at' },
        { text: this.$t('Totales'), align: 'start', value: 'lines' },
        { text: this.$t('Estado'), align: 'start', value: 'status_id' },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
    statuses () {
      return {
        1: 'ACTIVE',
        3: 'PREPARING',
        4: 'PAUSED',
        5: 'FINISHED',
        6: 'CANCELLED',
        9: 'PREPARING',
        13: 'SYNCING',
        14: 'WAITING',
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getMySync()
      },
      deep: true,
    },
  },
  created () {
    this.getMySync()
    this.refresh = setInterval(() => {
      const existNotFinished = this.content.filter((object) => {
        return object.status_id !== 4
      })
      if (existNotFinished.length > 0) {
        this.getMySync()
      }
    }, 10000)
  },
  mounted () {
  },
  beforeDestroy () {
    clearInterval(this.refresh)
  },
}
